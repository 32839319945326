import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import ContactStyles from './contact.module.css'
import {TextField, Dialog, DialogActions, DialogTitle, Button, DialogContent, DialogContentText} from '@material-ui/core'
import Axios from 'axios'


class ContactPage extends React.Component {
  state = {email: null, subject: null, content: null, alert: null}
  handleInputChange = item => event => {
      const value = event.target.value
      this.setState(state => ({...state, [item]: value}))
  }

  closeAlert = () => {
    this.setState(state => ({...state, alert:null}))
  }

  displayAlert = (alert) => {
    this.setState(state=>({...state, alert: alert }))
  }

  handleSend = () => {
    
    let config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    
    let data = {
      from: `Sonic Instruments <info@sonic-instruments.co>`,
      to: 'info@sonic-instruments.co',
      subject: this.state.subject,
      text: `<from: ${this.state.email}>\n\n${this.state.content}`
    }
    let obj = this;

    let emailValidator = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!this.state.subject || !this.state.content || !this.state.email) {
      obj.displayAlert('Please fill in all the boxes')
      return
    }
    if (!emailValidator.test(this.state.email)) {
      obj.displayAlert('Please enter an valid email address')
      return
    }
      Axios.post('https://api.sonic-instruments.co/api/sendEmail', data, config).then(function(response) {
        obj.displayAlert('Thanks for reaching out, we will get back to you shortly!')
      })
      .catch(function(err) {
        obj.displayAlert(`Oops, something went wrong... ${err}`)
      })

    
    }

  
  render() {
    return (
      <Layout location={this.props.location} title={'Contact'}>
        <SEO
          title={'Contact Sonic Hits'}
          keywords={[`music`, `MIDI`, `audio engineering`, `MIDI controller`]}
        />
        <div className={ContactStyles.cover} />
        <div className={ContactStyles.pageContainer}>
            <h1> Let's get in touch </h1>
            <div>
                <TextField variant="outlined" fullWidth margin="normal" type='email' label='Email address' required onChange={this.handleInputChange('email')}/>
            </div>
            <div>
                <TextField variant="outlined" fullWidth margin="normal" label='Subject' onChange={this.handleInputChange('subject')}/>
            </div>
            <div>
                <TextField variant="outlined"  fullWidth margin="normal" multiline label='Content' rows='6' required onChange={this.handleInputChange('content')}/>
            </div>
            <button style={{margin: 50, width: '40%'}} onClick={this.handleSend}> Send </button>
        </div>
        <div>
          <Dialog open={Boolean(this.state.alert)} onClose={this.closeAlert} aria-labelledby='alert-dialog-title' aria-describedby="alert-dialog-description">
            <DialogTitle id='alert-dialog-description'>Sonic Instruments</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {this.state.alert}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeAlert} color='primary'>
                Great
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Layout>
    )
  }
}

export default ContactPage
